import React, { useEffect, useContext } from "react";
import Header from "../components/layout/Header";
import SideBar from "../components/layout/SideBar";
import {
    GlobalDispatchContext,
  } from "../stores/GlobalContextProvider";
//import useBodyClass from '../hooks/setBodyClass';
import "../sass/main.scss";

const Layout = props => {
    const isClient = typeof window === "object";
    const dispatch = useContext(GlobalDispatchContext);

    useEffect(() => {
        console.log(document.getElementById("pageWrapper").className);
        // document.getElementById("pageWrapper").className = document.getElementById("pageWrapper").className.replace("no-js", "js");
        // handle browser resize
        const handleResize = () => {
            dispatch({
                type: "UPDATE_BROWSER_DIMENSIONS",
                payload: {
                    browserWidth: isClient ? window.innerWidth : undefined,
                    browserHeight: isClient ? window.innerHeight : undefined,
                    heroHeight: isClient ? (document.getElementById("hero") ? document.getElementById("hero").offsetHeight : 0) : 0
                }
            });
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isClient]);

    return (
        <>
            <Header />
            <div id="pageWrapper">
                <div className="main" id="main">
                    {props.children}
                </div>
                <SideBar />
            </div>
        </>
    );
};
export default Layout;
