import React, {useContext} from "react";
import {
    GlobalDispatchContext,
    GlobalStateContext,
  } from "../../stores/GlobalContextProvider";

import Logo from "./Logo";

const Footer = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const globalState = useContext(GlobalStateContext);

    const [randomCeleb, setRandomCeleb] = React.useState(Math.floor(Math.random() * 13) + 1);
    const doSameAsOnClick = () => ({ key }) => {
        if (key === "Enter") {
            setRandomCeleb(Math.floor(Math.random() * 13) + 1);
        }
    };

    return (
        <>
            <div className={`sideBar ${globalState.showInfo ? "overlayer" : ""}`}>
                {globalState.showInfo && (
                    <button
                        id="closeButton"
                        onClick={() => {
                            dispatch({ type: "SHOW_INFO", payload: false });
                        }}
                    >
                        CLOSE
                    </button>
                )}
                <div className="logo">
                    <Logo color={globalState.color ? globalState.color : "black"} />
                </div>
                <div className="copy">
                    <h2>Studio Milan Nedvěd is a graphic design practise with emphasis on typography – whether for screen or paper.</h2>
                    <br />
                    <a href="mailto:hello@mnmnmnmn.studio">E-MAIL</a>
                    <br />
                    <a href="https://www.instagram.com/mnmnmnmn.studio/" rel="noreferrer" target="_blank">INSTAGRAM</a>
                    <br />
                    <a href="https://bit.ly/MNMNMNMN_Newsletter" rel="noreferrer" target="_blank">NEWSLETTER</a>
                    <br />
                    <a href="https://linktr.ee/mnmnmnmn.studio" rel="noreferrer" target="_blank">LINKTR.EE</a>
                    <br />
                    (+420) 77 88 07 555
                    <br />
                    <br />
                    STUDIO ADDRESS (<a href="https://www.google.com/maps/@50.1028135,14.4215861,3a,75y,109.75h,97.25t/data=!3m8!1e1!3m6!1sAF1QipMxntR_vODVoO3oJIjlH5qqxmbSWIvc2j3E9-Np!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMxntR_vODVoO3oJIjlH5qqxmbSWIvc2j3E9-Np%3Dw203-h100-k-no-pi1.6758218-ya168.29059-ro0.27865574-fo100!7i7680!8i3840" rel="noreferrer" target="_blank">MAP</a>)
                    <br />
                    Nad Královskou oborou 161/11
                    <br />
                    170 00 Prague 7
                    <br />
                    Czech Republic, EU
                    <br />
                    <br />
                    SERVICES
                    <br />
                    Art Direction
                    <br />
                    Books
                    <br />
                    Exhibition Design &#38; Graphics
                    <br />
                    Identity Design
                    <br />
                    Printed Matter
                    <br />
                    Visual Campaigns
                    <br />
                    Website/Mobile Design
                    <br />
                    <br />
                    CLIENTS
                    <br />
                    Absynt.sk
                    <br />
                    Alšova jihočeská galerie
                    <br />
                    artforum
                    <br />
                    Bigg Boss
                    <br />
                    BoysPlayNice
                    <br />
                    Czech Centres
                    <br />
                    Czech Olympic Team
                    <br />
                    endorfilm
                    <br />
                    Havrlant Art Collection
                    <br />
                    J&amp;T Bank Magnus Art
                    <br />
                    Laterna Magika
                    <br />
                    Litografie Řiční
                    <br />
                    LUX nakladatelství
                    <br />
                    Max Ström Bokförlaget
                    <br />
                    Meander publishing
                    <br />
                    Ministry of Foreign Affairs
                    <br />
                    Moravská galerie v Brně
                    <br />
                    Museum Kampa
                    <br />
                    National Gallery Prague
                    <br />
                    National Theatre Prague
                    <br />
                    PositiF publishing
                    <br />
                    Sport in Art
                    <br />
                    Šépka architekti
                    <br />
                    Villa Pellé
                    <br />
                    Vosto5
                    <br />
                    Východočeské muzeum v Pardubicích
                    <br />
                    wo-men publishing
                    <br />
                    <br />
                    ARTISTS
                    <br />
                    Gustav Aulehla
                    <br />
                    Norman Behrendt
                    <br />
                    Natalia Dołgowska
                    <br />
                    Libor Fojtík
                    <br />
                    Roman Franc
                    <br />
                    David Gaberle
                    <br />
                    Fedor Gál
                    <br />
                    Dagmar Hochová
                    <br />
                    Hervé Jakubowicz
                    <br />
                    Jan Jindra
                    <br />
                    Erik Johansson
                    <br />
                    Radek Kalhous
                    <br />
                    Mirek Kaufman
                    <br />
                    kolektivní halucinace
                    <br />
                    Stanislav Kolíbal
                    <br />
                    Zdeněk Lhoták
                    <br />
                    Dita Pepe
                    <br />
                    Tomáš Pospěch
                    <br />
                    Bára Prášilová
                    <br />
                    Tomáš Predka
                    <br />
                    Phila Primus
                    <br />
                    Alexandr Skalický
                    <br />
                    Martina Sľúková
                    <br />
                    Kateřina Šedá
                    <br />
                    Karel Štědrý
                    <br />
                    Jindřich Štreit
                    <br />
                    Jana Šturdíková
                    <br />
                    TMBK
                    <br />
                    Pavel Vavroušek
                    <br />
                    Tereza Zelenková
                    <br />
                    <br />
                    AWARDS
                    <br />
                    Art Books Wanted International Award
                    <br />
                    Best Book Design from all over the World
                    <br />
                    Brno Biennial International Exhibition
                    <br />
                    European Design Awards
                    <br />
                    KALEID Editions
                    <br />
                    Les Rencontres d'Arles The Book Awards
                    <br />
                    The Best Czech Photography Books Awards
                    <br />
                    The Most Beautiful Czech Books
                    <br />
                    Red Dot Design Award
                    <br />
                    <br />
                    <div
                        className="randomCeleb"
                        onClick={() => {
                            setRandomCeleb(Math.floor(Math.random() * 13) + 1);
                        }}
                        onKeyPress={doSameAsOnClick()}
                        title="Hall of Fame"
                        role="button"
                        tabIndex="0"
                    >
                        <img src={`/celebs/${randomCeleb}.jpg`} alt="Hall Of Fame" />
                    </div>
                    <br />
                    MILAN NEDVĚD ● In the last two decades he has worked for digital, ad agencies and IT consultancies in Prague and London with focus on the web, digital and screen based design for renowned local and global brands (eg. Accenture, ČSOB, Erste Česká spořitelna, ING, Google, KangaROOS, Kraft Foods, Nike, Nokia, Pilsner Urquell, Vodafone).<br /><br />He holds an MFA in graphic design from the Rhode Island School of Design, USA.
                    <br />
                    <br />
                    INTERNSHIPS ● Yes! Interns are welcome! We need you to understand česky or speak English. No capiamo altre lingue. To apply simply <a href="mailto:hello@milann.info?subject=Hello, can I haz a internship?">e-mail</a> us with a link to your work and briefly introduce yourself. We don’t read cover letters — we just like to look at your work and your choice of typefaces! 
                    <br />
                    <br />
                    MNMNMNMN &copy; 2024
                    <br />
                    This runs on DataBitch 2.0
                    <br />
                    Do you <a href="mailto:hello@mnmnmnmn.studio?subject=I like your website, can I have one too?">like</a> it?
                    <br />
                    <br />
                    If you have read so far you deserve a <a href="https://www.youtube.com/watch?v=uJwekkbGPns" rel="noreferrer" target="_blank">cookie</a>.
                    <br />
                    <br />
                    Web development ● <a href="http://www.code8.cz/" rel="noreferrer" target="_blank">code 8</a>
                </div>
            </div>
        </>
    );
};
export default Footer;
