import React from "react";

const Logo = ({ color }) => {
    // console.log(color);
    return (
        <svg version="1.1" id="cmaranice" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 156 260" enableBackground="new 0 0 156 260">
            <polygon
                style={{ fill: color }}
                points="126.7,0 116.8,0.6 116.8,179.1 97.5,0 87.6,0.6 87.6,179.1 68.3,0 58.4,0.6 58.4,179.1 39.1,0 29.2,0.6 29.2,179.1 
                9.9,0 0,0.6 0,256 9.9,256 9.9,93 27.6,257.1 37.5,256.1 37.5,256 39.1,256 39.1,93 56.8,257.1 66.7,256.1 66.7,256 68.3,256 
                68.3,93 86,257.1 95.9,256.1 95.9,256 97.5,256 97.5,93 115.2,257.1 125.1,256.1 125.1,256 126.7,256 126.7,93 144.4,257.1 
                154.3,256.1 154.3,255.9 154.5,255.9 154.5,0.7 144.5,0.7 144.5,165.2 "
            />
        </svg>
    );
};
export default Logo;
