import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const isClient = typeof window === "object";
const initialGlobalState = {
    view: isClient && localStorage.getItem("view") ? localStorage.getItem("view") : "full",
    color: null,
    showInfo: false,
    workItem: null,
    slideItem: null,
    browserWidth: isClient ? window.innerWidth : undefined,
    browserHeight: isClient ? window.innerHeight : undefined,
    heroHeight: isClient ? (document.getElementById("hero") ? document.getElementById("hero").offsetHeight : 0) : 0
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_VIEW":
        return {
            ...state,
            view: action.payload
        };
    case "SHOW_INFO":
        return {
            ...state,
            showInfo: action.payload
        };
    case "SET_COLOR":
        return {
            ...state,
            color: action.payload
        };
    case "SET_CURRENT_ITEM":
        return {
            ...state,
            workItem: action.payload
        };
    case "SET_SLIDE":
        return {
            ...state,
            slideItem: action.payload
        };
    case "UPDATE_BROWSER_DIMENSIONS":
        return {
            ...state,
            browserWidth: action.payload.browserWidth,
            browserHeight: action.payload.browserHeight,
            heroHeight: action.payload.heroHeight
        };
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialGlobalState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider